<template>

  <section id="reports">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <h5 class="mb-0 color-gray">{{ title_component.subtitle }}</h5>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-xl-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="indicadores_list" 
          v-model="indicadores_selected"
          :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-xl-9">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-3 mb-3">
            <v-select 
              class="selvue-custom"
              :options="cantidad_list" 
              v-model="cantidad_selected"
              :clearable="false">
            </v-select>
          </div>
          <div 
            v-if="cantidad_selected.label == 'Historico'"
            class="col-12 col-lg-7 col-xl-8"></div>
          <div 
            v-if="cantidad_selected.label == 'Anual'"
            class="col-12 col-lg-7 col-xl-4 offset-xl-4 mb-3">
            <v-select 
              class="selvue-custom"
              :options="year_list" 
              v-model="year_selected"
              :clearable="false">
            </v-select>
          </div>
          <div 
            v-if="cantidad_selected.label == 'Rango'"
            class="col-12 col-lg-7 col-xl-8">
            <div class="row">
              <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
                <font-awesome-icon 
                    icon="filter" 
                    class="color-secondary pe-1"
                  />
                  Filtrar
              </div>
              <div class="col-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">
                <div class="row" v-click-outside="handleCalendar">
                  <div class="col-6">
                    <div 
                      class="form-control input-calendar" 
                      @click="openCalendar('start-date')">
                      <span>{{ dateFormatymd(init_date) }}</span>
                      <div class="icon">
                        <font-awesome-icon icon="calendar"/>
                      </div>
                      <div
                        @click="clearDates()"
                        v-show="init_date != 'Inicio'"
                        class="input-calendar-clean">
                        <font-awesome-icon icon="times"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div 
                      class="form-control input-calendar disabled" 
                      v-if="init_date == 'Inicio'">
                      <span>{{ end_date }}</span>
                      <div class="icon">
                        <font-awesome-icon icon="calendar"/>
                      </div>
                    </div>
                    <div
                      class="form-control input-calendar"
                      v-if="init_date != 'Inicio'"
                      @click="openCalendar('end-date')">
                      <span>{{ dateFormatymd(end_date) }}</span>
                      <div class="icon">
                        <font-awesome-icon icon="calendar"/>
                      </div>
                      <div
                        @click="clearDates()"
                        v-show="end_date != 'Término'"
                        class="input-calendar-clean">
                        <font-awesome-icon icon="times"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 position-relative">
                    <div 
                      class="input-calendar-dropdown" 
                      :class="calendar_side" 
                      v-if="show_calendar">
                      <v-calendar
                        color="blue"
                        :max-date="max_date"
                        :min-date="min_date"
                        :attributes="attributes"
                        @dayclick="onDayClick"
                      />
                      <div 
                        class="border-arrow" 
                        :class="calendar_side">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 col-xl-1 mb-3">
            <a 
              v-if="cantidad_selected.label == 'Historico'"
              @click="getReport(0, 2)"
              href="javascript:"
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill">
              <font-awesome-icon icon="download"/>
            </a>
            <a 
              v-if="cantidad_selected.label == 'Anual'"
              @click="getReport(2, 2)"
              href="javascript:"
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill">
              <font-awesome-icon icon="download"/>
            </a>
            <a 
              v-if="cantidad_selected.label == 'Rango'"
              @click="getReport(1, 2)"
              href="javascript:"
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill">
              <font-awesome-icon icon="download"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="card card-shadow border-round-10 border-0 mb-3">
          <div class="card-body">
            <div class="row d-flex align-items-center mb-3">
              <div class="col-12 col-lg-7">
                <h5 class="font-main-bold color-gray">Detalle reconocimientos</h5>
              </div>
              <div class="col-12 col-lg-5 input-search">
                <input 
                    type="text" 
                    placeholder="Buscar un colaborador" 
                    class="form-control input-custom"/>
                    <span class="input-search-icon">
                      <font-awesome-icon icon="search"/>
                    </span>
              </div>
            </div>
            <div class="list-group">
              <div                     
                v-for="(el, i) in list" :key="i">
                <a 
                  href="javascript:" 
                  @click="list_selected = el"
                  class="list-group-item list-group-item-action border-0 border-bottom">
                    <div class="d-flex flex-column flex-xxl-row align-items-xxl-center">
                      <div class="d-flex flex-grow-1 align-items-center">
                        <div class="flex-shrink-0">
                          <img
                            class="img-obj img-obj-60 img-obj-circle border"
                            v-if="el.reconocidos[0].imagen_perfil"
                            :src="el.reconocidos[0].imagen_perfil"
                          />
                          <img
                            class="img-obj img-obj-60 img-obj-circle"
                            v-if="!el.reconocidos[0].imagen_perfil"
                            :src="user_default_img"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="card-subtitle font-main color-gray">
                            {{ el.reconocidos[0].primer_nombre | capitalize }}
                            {{ el.reconocidos[0].apellido_paterno | capitalize }} 
                            ha sido
                            <font-awesome-icon icon="star" class="color-yellow"/> 
                            por
                            <font-awesome-icon icon="circle-user" class="color-light"/>
                            {{ el.reconocedor[0].primer_nombre | capitalize }}
                            {{ el.reconocedor[0].apellido_paterno | capitalize }}
                          </h5>
                          <p class="card-text color-light">{{ dateNewFormat(el.fecha_creacion) }}</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-end p-3">
                        <font-awesome-icon icon="heart" class="fs-4 color-rose-shadow"/>
                        <span class="color-light px-2">{{ el.likes.length }}</span>
                        <a 
                          class="link-secondary text-decoration-none"
                          href="javascript:">
                          Ver detalle
                        </a>
                      </div>
                    </div>
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card card-shadow border-round-10 border-0 mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h5 class="font-main-bold color-gray mb-3">Información del usuario</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xxl-6 mb-3">
                <label>Reconocimiento</label>
                <input 
                  v-if="list_selected == null"
                  type="text" 
                  value=""
                  class="form-control input-custom"/>
                  <input 
                    v-else
                    :value="`${list_selected.valor_comportamiento[0].valor.nombre_valor}`"
                    type="text" 
                    class="form-control input-custom"/>
              </div>
              <div class="col-12 col-xxl-6 mb-3">
                <label>Relación</label>
                <input 
                  v-if="list_selected == null" 
                  type="text" 
                  value=""
                  class="form-control input-custom"/>
                  <input 
                    v-else
                    type="text" 
                    :value="`${list_selected.relacion[0].nombre_relacion}`"
                    class="form-control input-custom"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mb-3">
                <label>Argumento</label>
                <textarea 
                  v-if="list_selected == null"
                  value=""
                  class="form-control textarea-custom textarea-custom-resize">
                </textarea>
                <textarea 
                  v-else
                  :value="`${list_selected.comentario_reconocimiento}`"
                  class="form-control textarea-custom textarea-custom-resize">
                </textarea>
              </div>
            </div>

            <div 
              v-if="list_selected !== null" 
              class="row">
              <div class="col-12">
                <div 
                  v-if="list_selected.url_imagen === ''" 
                  class="alert alert-warning mb-0 text-center">
                  <font-awesome-icon icon="circle-info"/>
                  No hay imagen en esta publicación
                </div>
                <img 
                  class="img-fluid img-thumbnail"
                  v-if="list_selected.url_imagen !== ''" 
                  :src="list_selected.url_imagen"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--     <div class="reports">
      <div class="tabs">
        <SectionTabs :tabs="tab_component" />
      </div>
      <div class="reports-top">
        <h3>
          {{ title_component.subtitle }}
        </h3>
      </div>
      <div class="reports-container">
        <div class="download-top">
          <div class="download-top-left">
            <div
              class="select-container"
              :class="open_indicadores_select_list && 'active'"
            >
              <div class="select-btn" @click="openSelectIndicadores">
                <div class="item">
                  {{ indicadores_selected }}
                </div>
                <div class="icon">
                  <font-awesome-icon
                    :icon="
                      open_indicadores_select_list ? 'angle-up' : 'angle-down'
                    "
                  >
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_indicadores_select_list">
                <div
                  v-for="(indi, k) in indicadores_list"
                  :key="k"
                  class="list-item"
                  @click="selectIndicador(indi)"
                >
                  {{ indi.label }}
                </div>
              </div>
            </div>
            <div
              class="select-container"
              :class="open_cantidad_select_list && 'active'"
            >
              <div class="select-btn" @click="openSelectCantidad">
                <div class="item">
                  {{ cantidad_selected }}
                </div>
                <div class="icon">
                  <font-awesome-icon
                    :icon="open_cantidad_select_list ? 'angle-up' : 'angle-down'"
                  >
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_cantidad_select_list">
                <div
                  v-for="(el, k) in cantidad_list"
                  :key="k"
                  class="list-item"
                  @click="selectCantidad(el)"
                >
                  {{ el.label }}
                </div>
              </div>
            </div>
            <div
              v-if="cantidad_selected == 'Anual'"
              class="select-container"
              :class="open_select_list && 'active'"
            >
              <div class="select-btn" @click="openSelectYear">
                <div class="item">
                  {{ year_selected }}
                </div>
                <div class="icon">
                  <font-awesome-icon
                    :icon="open_select_list ? 'angle-up' : 'angle-down'"
                  >
                  </font-awesome-icon>
                </div>
              </div>
              <div class="select-list" v-if="open_select_list">
                <div
                  v-for="(year, k) in year_list"
                  :key="k"
                  class="list-item"
                  @click="selectYear(year)"
                >
                  {{ year }}
                </div>
              </div>
            </div>
            <div
              v-if="cantidad_selected == 'Rango'"
              class="calendar-container"
              v-click-outside="handleCalendar"
            >
              <div class="calendar" :class="calendar_side" v-if="show_calendar">
                <v-calendar
                  color="blue"
                  :max-date="max_date"
                  :min-date="min_date"
                  :attributes="attributes"
                  @dayclick="onDayClick"
                />
                <div class="border-arrow" :class="calendar_side">
                  <div class="arrow"></div>
                </div>
              </div>
              <div class="start" @click="openCalendar('start-date')">
                <span>{{ init_date }}</span>
                <div class="icon">
                  <font-awesome-icon icon="calendar-day"> </font-awesome-icon>
                </div>
              </div>
              <div class="end unactive" v-if="init_date == 'Inicio'">
                <span>{{ end_date }}</span>
                <div class="icon">
                  <font-awesome-icon icon="calendar-day"> </font-awesome-icon>
                </div>
              </div>
              <div
                class="end"
                v-if="init_date != 'Inicio'"
                @click="openCalendar('end-date')"
              >
                <span>{{ end_date }}</span>
                <div class="icon">
                  <font-awesome-icon icon="calendar-day"> </font-awesome-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="download-top-rigth">
            <div
              class="download-btn"
              v-if="cantidad_selected == 'Historico'"
            >
              <a @click="getReport(0, 2)"><img :src="img_dowload_w" alt="" /></a>
            </div>
            <div
              class="download-btn"
              v-if="cantidad_selected == 'Anual'"
            >
              <a @click="getReport(2, 2)"><img :src="img_dowload_w" alt="" /></a>
            </div>
            <div
              class="download-btn"
              v-if="cantidad_selected == 'Rango'"
            >
              <a @click="getReport(1, 2)"><img :src="img_dowload_w" alt="" /></a>
            </div>
          </div>
        </div>
        <div class="download-container">
          <div class="download-container-left con">
            <div class="title">
              <div class="title-text">Detalle reconocimientos</div>
            </div>
            <div class="list">
              <div class="list-items">
                <div class="list-items-item" v-for="(el, i) in list" :key="i">
                  <div class="list-items-item-left">
                    <div class="list-items-item-left-img">
                      <img
                        v-if="el.reconocidos[0].imagen_perfil"
                        :src="el.reconocidos[0].imagen_perfil"
                      />
                      <img
                        v-if="!el.reconocidos[0].imagen_perfil"
                        :src="user_default_img"
                      />
                    </div>
                    <div class="list-items-item-left-cong">
                      <div class="list-items-item-left-cong-text">
                        {{ el.reconocidos[0].primer_nombre }}
                        {{ el.reconocidos[0].apellido_paterno }} ha sido
                        <img :src="img" alt="" /> por
                        {{ el.reconocedor[0].primer_nombre }}
                        {{ el.reconocedor[0].apellido_paterno }}
                      </div>
                      <div class="list-items-item-left-cong-fecha">
                        {{ dateNewFormat(el.fecha_creacion) }}
                      </div>
                    </div>
                  </div>
                  <div class="list-items-item-right">
                    <div class="list-items-item-right-img">
                      <img
                        v-if="el.reconocedor[0].imagen_perfil"
                        :src="el.reconocedor[0].imagen_perfil"
                      />
                      <img
                        v-if="!el.reconocedor[0].imagen_perfil"
                        :src="user_default_img"
                      />
                    </div>
                    <div class="list-items-item-right-like">
                      +{{ el.likes.length }}
                      <img src="../../assets/img/like-solid.svg" alt="" />
                    </div>
                    <div
                      class="list-items-item-right-det"
                      @click="list_selected = el"
                    >
                      Ver detalle
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="download-container-rigth con">
            <div class="title">
              <div class="title-text">Información del usuario</div>
            </div>
            <div class="data">
              <div class="data-item">
                <div class="data-item-text">Reconocimiento:</div>
                <div class="data-item-input">
                  <input v-if="list_selected == null" type="text" value="" />
                  <input
                    v-else
                    type="text"
                    :value="`${list_selected.valor_comportamiento[0].valor.nombre_valor}`"
                  />
                </div>
              </div>
              <div class="data-item ri">
                <div class="data-item-text">Relación:</div>
                <div class="data-item-input">
                  <input v-if="list_selected == null" type="text" value="  " />
                  <input
                    v-else
                    type="text"
                    :value="`${list_selected.relacion[0].nombre_relacion}`"
                  />
                </div>
              </div>
              <div class="data-item long">
                <div class="data-item-text">Argumento:</div>
                <div class="data-item-input long-input">
                  <textarea v-if="list_selected == null" type="text" value="  " />
                  <textarea
                    v-else
                    type="text"
                    :value="`${list_selected.comentario_reconocimiento}`"
                  />
                </div>
              </div>
              <div class="data-item img long">
                <img v-if="list_selected == null" src="" alt="" />
                <img v-else :src="list_selected.url_imagen" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>

</template>

<script>
//import SectionTitle from "../Section/SectionTitle.vue";
import SectionTabs from "../Section/SectionTabs.vue";
import XLSX from "xlsx";
import { GET_REPORT } from "@/apollo/queries";
export default {
  components: {
    // SectionTitle,
    SectionTabs,
  },
  data() {
    return {
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-reports",
        },
      ],
      list: [],
      list_selected: null,
      indicadores_list: [
        { label: "Año", route: "recognize-year" },
        { label: "Gerencia", route: "recognize-management" },
        { label: "Planta", route: "recognize-age" },
        { label: "Top 20", route: "recognize-ranking" },
        { label: "Consultas por colaborador", route: "recognize-contributor" },
        { label: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Detalles de reconocimiento",
      open_indicadores_select_list: false,
      cantidad_list: [
        { label: "Historico", id: "1" },
        { label: "Anual", id: "2" },
        { label: "Rango", id: "3" },
      ],
      cantidad_selected: { label:"Historico", id:"1" },
      open_cantidad_select_list: false,

      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      title_component: {
        title: "Reconoce",
        subtitle:
          "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      init_date: "Inicio",
      end_date: "Término",
      year_list: ["2022", "2023", "2024", "2025"],
      year_selected: "2022",
      open_select_list: false,
      show_calendar: false,
      calendar_side: "",
      max_date: new Date(),
      min_date: "",
      type_report: 0,
      user_default_img: require("@/assets/img/user_default_img.svg"),
      img: require("@/assets/img/reconoze/ball-star.svg"),
      img_dowload_w: require("@/assets/img/utilities/dowload-w.svg"),
    };
  },
  watch: {
    year_selected() {
      this.getReport(2, 1);
    },
    init_date() {
      this.getReport(1, 1);
    },
    end_date() {
      this.getReport(1, 1);
    },
    indicadores_selected(value) {
    this.$router.push({ name: value.route });
  }
  },
  computed: {
    // Funcion del plugin del calendario para destacar el dia seleccionado
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  mounted() {
    this.getReport(0, 1);
  },
  methods: {
    getReport(t, dow) {
      this.type_report = t;
      var f_ini = "";
      var f_end = "";
      if (t == 1) {
        f_ini = this.init_date;
        f_end = this.end_date;
      }
      if (t == 2) {
        f_ini = `${this.year_selected}-01-01`;
        f_end = `${this.year_selected}-12-31`;
      }
      //console.log(f_ini);
      //console.log(f_end);
      this.$apollo
        .query({
          query: GET_REPORT,
          variables: {
            id_empresa: this.id_empresa,
            tipo_reporte: t == 2 ? 1 : t,
            fecha_inicio: f_ini,
            fecha_fin: f_end,
          },
        })
        .then((res) => {
          var resp = res.data.reconocimientosReportes;
          if (dow == 1) {
            this.list = resp;
            //console.log(resp);
          }
          if (dow == 2) {
            this.dataFormat(resp);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    clearDates(){
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      this.date_start = '';
      this.date_end = '';
      this.inicio = '';
      this.fin = '';
      this.show_calendar = false;
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
      } else {
        var min = new Date(this.init_date);
        this.min_date = min.setDate(min.getDate() + 1);
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" && this.end_date != "Término") {
        this.getReport(1, 1);
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
    dataFormat(res) {
      var report = {};
      var report_arr = [];
      var title = `Reconocimientos_Totales`;
      for (var i = 0; i < res.length; i++) {
        console.log(res[i]);
        var reconocido = res[i].reconocidos[0];
        var reconocedor = res[i].reconocedor[0];

        report = {
          comentario: res[i].comentario_reconocimiento,
          valor: res[i].valor_comportamiento[0].valor.nombre_valor,
          relacion: res[i].relacion[0].nombre_relacion,
          fecha_creacion: this.dateFormat(res[i].fecha_creacion),
          likes: res[i].likes.length,

          reconocido_nombre: reconocido.primer_nombre,

          reconocido_apellido_pa: reconocido.apellido_paterno,
          reconocido_apellido_ma: reconocido.apellido_materno,
          reconocido_rut: `${reconocido.rut_usuario}-${reconocido.dv_usuario}`,
          reconocido_cargo:
            reconocido.cargo == null
              ? "Sin cargo"
              : reconocido.cargo.nombre_cargo,
          reconocido_gerencia:
            reconocido.gerencia == null
              ? "sin gerencia"
              : reconocido.gerencia.nombre_gerencia,

          reconocedor_nombre: reconocedor.primer_nombre,
          reconocedor_apellido_pa: reconocedor.apellido_paterno,
          reconocedor_apellido_ma: reconocedor.apellido_materno,
          reconocedor_rut: `${reconocedor.rut_usuario}-${reconocedor.dv_usuario}`,
          reconocedor_cargo:
            reconocedor.cargo == null
              ? "Sin cargo"
              : reconocedor.cargo.nombre_cargo,
          reconocedor_gerencia:
            reconocedor.gerencia == null
              ? "sin gerencia"
              : reconocedor.gerencia.nombre_gerencia,
        };
        report_arr.push(report);
        report = {};
      }
      if (this.type_report == 1) {
        title = `RC_${this.init_date}_y_${this.end_date}`;
        //console.log(title, title.length);
      }
      if (this.type_report == 2) {
        title = `Reconocimientos_del_año_${this.year_selected}`;
      }
      //console.log(report_arr);
      this.printJson(report_arr, title);
    },
    dateFormat(date) {
      if (date === undefined || date === null) {
        return "";
      } else {
        date = new Date(date);
        //console.log(
        //  `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
        //);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }
    },
    printJson(json, filename) {
      let data = XLSX.utils.json_to_sheet(json);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    openSelectYear() {
      this.open_select_list = !this.open_select_list;
    },
    selectYear(year) {
      this.year_selected = year;
      this.open_select_list = false;
    },
    openSelectIndicadores() {
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    selectIndicador(indi) {
      if (this.indicadores_selected != indi.name) {
        this.$router.push({ name: indi.route });
        this.indicadores_selected = indi.name;
      }
      this.open_indicadores_select_list = false;
    },
    openSelectCantidad() {
      this.open_cantidad_select_list = !this.open_cantidad_select_list;
    },
    selectCantidad(indi) {
      if (this.cantidad_selected != indi.label) {
        this.cantidad_selected = indi.label;
      }
      this.open_cantidad_select_list = false;
    },
    //Formatear fecha
    dateNewFormat(date) {
      var day = new Date(date);
      var month = day.getMonth() + 1;
      return `${day.getDate()}/${
        month < 10 ? `0${month}` : month
      }/${day.getFullYear()}`;
    },
  },
  //FF
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>
